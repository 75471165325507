// src/components/HeroSection.js
import React from 'react';
import { Button } from 'react-bootstrap';
import './HeroSection.css'; // Импорт стилей

const HeroSection = () => {
    return (
        <section className="hero-section">
            <div className="background-animation">
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
                <div className="particle"></div>
                <div className="particle"></div>
                <div className="particle"></div>
                <div className="particle"></div>
            </div>
            <div className="hero-content">
                <h1 className="hero-slogan">Искусство, которое вдохновляет</h1>
                <p className="hero-description">
                    Погружение в мир татуировки, где каждый дизайн рассказывает историю. Запишитесь на консультацию и
                    создайте уникальное произведение искусства.
                </p>
                <Button variant="primary" className="hero-button">Записаться на консультацию</Button>
            </div>
        </section>
    );
};

export default HeroSection;
