// src/components/Footer.js
import React from 'react';
import { Container, Row, Col, Nav } from 'react-bootstrap';
import './Footer.css'; // Импорт стилей

const Footer = () => {
    return (
        <footer className="footer">
            <Container>
                <Row className="footer-content">
                    <Col xs={12} md={4} className="footer-section">
                        <h5>Контактная информация</h5>
                        <p>
                            <strong>Адрес:</strong> ул. Примерная, 123, Москва, Россия
                        </p>
                        <p>
                            <strong>Телефон:</strong> +7 (123) 456-78-90
                        </p>
                        <p>
                            <strong>Email:</strong> info@example.com
                        </p>
                    </Col>
                    <Col xs={12} md={4} className="footer-section">
                        <h5>Социальные сети</h5>
                        <ul className="social-links">
                            <li><a href="https://facebook.com" target="_blank" rel="noopener noreferrer">Facebook</a></li>
                            <li><a href="https://instagram.com" target="_blank" rel="noopener noreferrer">Instagram</a></li>
                            <li><a href="https://twitter.com" target="_blank" rel="noopener noreferrer">Twitter</a></li>
                        </ul>
                    </Col>
                    <Col xs={12} md={4} className="footer-section">
                        <div className="footer-nav-container">
                            <ul className="footer-nav">
                                <li><Nav.Link href="#home" className="footer-nav-link">Главная</Nav.Link></li>
                                <li><Nav.Link href="#about" className="footer-nav-link">О мастере</Nav.Link></li>
                                <li><Nav.Link href="#portfolio" className="footer-nav-link">Портфолио</Nav.Link></li>
                                <li><Nav.Link href="#contact" className="footer-nav-link">Контакты</Nav.Link></li>
                            </ul>
                        </div>
                    </Col>
                </Row>
            </Container>
            <div className="footer-bottom">
                <p>&copy; {new Date().getFullYear()} Xolzzz. Все права защищены.</p>
            </div>
        </footer>
    );
};

export default Footer;
