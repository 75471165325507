// src/components/PortfolioCarousel.js
import React from 'react';
import { Carousel, Container } from 'react-bootstrap';
import './PortfolioCarousel.css';

//import img1 from '../assets/images/8.jpg';
//import img2 from '../assets/images/9.jpg';


const images = [
    'https://via.placeholder.com/1200x600?text=Фото+2',
    'https://via.placeholder.com/1200x600?text=Фото+3',
    // Замените на реальные URL ваших изображений
];

const PortfolioCarousel = () => {
    return (
        <div id="production" className="production-gallery-section">
            <Container>
                <h2 className="section-title">Портфолио</h2>
                <Carousel>
                    {images.map((image, index) => (
                        <Carousel.Item key={index}>
                            <img
                                className="d-block w-100"
                                src={image}
                                alt={`Слайд ${index + 1}`}
                            />
                        </Carousel.Item>
                    ))}
                </Carousel>
            </Container>
        </div>
    );
};

export default PortfolioCarousel;
