// src/components/Header.js
import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import './Header.css';  // Импорт стилей

const Header = () => {
    return (
        <Navbar bg="dark" variant="dark" expand="lg" className="header-navbar">
            <Container>
                <Navbar.Brand href="#home" className="header-brand">Xolzzz</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="header-nav">
                        <Nav.Link href="#home" className="header-nav-link">
                            Главная
                        </Nav.Link>
                        <Nav.Link href="#about" className="header-nav-link">
                            О мастере
                        </Nav.Link>
                        <Nav.Link href="#portfolio" className="header-nav-link">
                            Портфолио
                        </Nav.Link>
                        <Nav.Link href="#contact" className="header-nav-link">
                            Контакты
                        </Nav.Link>
                    </Nav>
                    <div className="header-info">
                        <a href="#addres" className="header-address">ул. Примерная, 123</a>
                        <span className="header-phone">+7 (123) 456-78-90</span>
                    </div>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default Header;
