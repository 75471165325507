// src/components/ServicesSection.js
import React from 'react';
import { Card } from 'react-bootstrap';
import Slider from 'react-slick';
import './ServicesSection.css'; // Импорт стилей

const services = [
    {
        title: 'Татуировки',
        description: 'Индивидуальные татуировки, которые отражают вашу личность и стиль.',
        imgSrc: 'https://via.placeholder.com/800x600?text=Tattoo+1', // Пример изображения
    },
    {
        title: 'Перекрытие старых тату',
        description: 'Исправление и перекрытие старых татуировок с использованием современных технологий.',
        imgSrc: 'https://via.placeholder.com/800x600?text=Tattoo+2', // Пример изображения
    },
    {
        title: 'Консультации',
        description: 'Профессиональные консультации по выбору дизайна и уходу за татуировками.',
        imgSrc: 'https://via.placeholder.com/800x600?text=Tattoo+3', // Пример изображения
    },
    {
        title: 'Консультации',
        description: 'Профессиональные консультации по выбору дизайна и уходу за татуировками.',
        imgSrc: 'https://via.placeholder.com/800x600?text=Tattoo+3', // Пример изображения
    },
    // Добавьте больше услуг здесь
];

const ServicesSection = () => {
    // Настройки для слайдера
    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false, // Отключите стрелки, если хотите только точки
    };

    return (
        <section className="services-section">
            <h2 className="services-title">Услуги</h2>
            <div className="services-content">
                {/* Слайдер для мобильных устройств */}
                <div className="d-block d-md-none services-slider">
                    <Slider {...sliderSettings}>
                        {services.map((service, index) => (
                            <Card className="service-card" key={index}>
                                <Card.Img variant="top" src={service.imgSrc} alt={service.title} />
                                <Card.Body>
                                    <Card.Title>{service.title}</Card.Title>
                                    <Card.Text>{service.description}</Card.Text>
                                </Card.Body>
                            </Card>
                        ))}
                    </Slider>
                </div>
                {/* Решение для десктопа */}
                <div className="d-none d-md-block">
                    <div className="services-grid">
                        {services.map((service, index) => (
                            <Card className="service-card" key={index}>
                                <Card.Img variant="top" src={service.imgSrc} alt={service.title} />
                                <Card.Body>
                                    <Card.Title>{service.title}</Card.Title>
                                    <Card.Text>{service.description}</Card.Text>
                                </Card.Body>
                            </Card>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ServicesSection;
