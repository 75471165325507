// src/App.js
import React from 'react';
import Header from './components/Header';
import HeroSection from './components/HeroSection';
import ServicesSection from './components/ServicesSection';
import PortfolioCarousel from './components/PortfolioCarousel';
import Footer from './components/Footer';


function App() {
    return (
        <div>
            <Header />
            <HeroSection />
            <ServicesSection />
            <PortfolioCarousel />
            <Footer />
        </div>
    );
}

export default App;
